import { Article } from '@/Article';
import { ArrowBtn } from '@/buttons';
import CLink from '@/CLink';
import { getSubNavLink } from '@/layout/NavLinks';
import { Seo } from '@/layout/Seo';
import { caption, textGradient } from '@/layout/styles/Typography';
import { Tag } from '@/Tag';
import { TeamPics } from '@/TeamPics';
import { css } from '@emotion/react';
import { StaticImage } from 'gatsby-plugin-image';
import { Fragment } from 'react';

const headingStyles = css`
    ${textGradient};
    margin: 0 0 32px;
    text-align: center;
    font-weight: 600;

    & + p {
        ${caption};
        margin: 0 auto 48px;
        max-width: 450px;
    }

    @media (min-width: 1024px) {
        font-weight: 700;
    }
`;

const btnStyles = css`
    margin: 48px auto 0;
    display: block;
    width: fit-content;
`;

export default function OurTeam() {
    return (
        <Fragment>
            <Seo
                title="Our Team | Simhairy Dental"
                description="If you are looking for a friendly, compassionate, and experienced dental practice then Simhiary Dental is the place for you. Learn more about our team!"
            />
            <Article>
                <Tag text="about" />
                <h2>Our Team</h2>
                <StaticImage
                    src="../../../assets/images/about/team/zhero.jpg"
                    alt="simhairy dental team"
                    layout="fullWidth"
                />
                <h2 css={headingStyles}>We Make it Happen, Together!</h2>
                <p>
                    From the moment you enter the practise, to the moment you see the doctor and
                    finally the follow ups, our team works tirelessly to make your experience the
                    most friendly and positive as possible.
                </p>
                <TeamPics />
                <ArrowBtn as={CLink} to={getSubNavLink('our doctors')} css={btnStyles}>
                    Get to know the Doctors
                </ArrowBtn>
            </Article>
        </Fragment>
    );
}
