import { flexCenter } from '@/layout/styles/classes';
import { caption } from '@/layout/styles/Typography';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData, ImageDataLike } from 'gatsby-plugin-image';

const Team = styled.div`
    ${flexCenter}
    flex-wrap: wrap;
    text-align: center;
    color: ${({ theme }) => theme.colors.dark};
    margin: calc(48px / -2) calc(80px / -2);

    > div {
        margin: calc(48px / 2) calc(80px / 2);

        > .gatsby-image-wrapper {
            border-radius: 50%;
        }

        > h3 {
            margin: 16px 0 0;
            text-transform: capitalize;
        }
        > p {
            ${caption};
            margin: 0;
            text-transform: capitalize;
        }
    }
`;

export const TeamPics = () => {
    type DataType = {
        allFile: {
            nodes: {
                childImageSharp: ImageDataLike;
                name: string;
            }[];
        };
    };
    const data: DataType = useStaticQuery(graphql`
        query TeamImages {
            allFile(
                filter: { relativeDirectory: { eq: "about/team" } }
                sort: { order: ASC, fields: name }
                limit: 5
            ) {
                nodes {
                    childImageSharp {
                        gatsbyImageData(width: 300, height: 300)
                    }
                }
            }
        }
    `);

    const members = [
        {
            image: getImage(data.allFile.nodes[0].childImageSharp) as IGatsbyImageData,
            name: 'dina h.',
            position: 'office manager',
        },
        {
            image: getImage(data.allFile.nodes[1].childImageSharp) as IGatsbyImageData,
            name: 'leen j.',
            position: 'receptionist',
        },
        {
            image: getImage(data.allFile.nodes[2].childImageSharp) as IGatsbyImageData,
            name: 'halah s.',
            position: 'dental assistant',
        },
        {
            image: getImage(data.allFile.nodes[3].childImageSharp) as IGatsbyImageData,
            name: 'krystal l.',
            position: 'dental assistan',
        },
        {
            image: getImage(data.allFile.nodes[4].childImageSharp) as IGatsbyImageData,
            name: 'sandy s.',
            position: 'receptionist / dental assitant',
        },
    ];

    return (
        <Team>
            {members.map((profile, i) => (
                <div key={i}>
                    <GatsbyImage
                        image={profile.image}
                        alt={`${profile.name} ${profile.position}`}
                    />
                    <h3>{profile.name}</h3>
                    <p>{profile.position}</p>
                </div>
            ))}
        </Team>
    );
};
